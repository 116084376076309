<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Returnable Order List</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="merchant_id">Merchant</label>
                                            <v-select name="merchant_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total SLA Missed</th>
                                                    <th>Total SLA Missed (ISD)</th>
                                                    <th>Total SLA Missed (OSD)</th>
                                                    <th>Total SLA Missed (SA)</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold curson-pointer">
                                                    <td @click="searchSlaMissedOrder(0)">{{ summary ? summary.total_sla_missed : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(1)">{{ summary ? summary.total_sla_missed_isd : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(2)">{{ summary ? summary.total_sla_missed_osd : 0 }}</td>
                                                    <td @click="searchSlaMissedOrder(3)">{{ summary ? summary.total_sla_missed_sa : 0 }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="collectionTable" class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <th>Total Order</th>
                                                    <th style="text-align:center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order,key) in listData" :key="key">
                                                    <td>{{ order.merchant_business }}</td>
                                                    <td>{{ order.total_order }}</td>
                                                    <td style="text-align:center">
                                                        <button class="btn btn-danger btn-sm" @click="makeReturn(order.merchant_id, search.start_date, search.end_date)" title="Make Return"> Make Return</button>    
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'Due',
        data(){
            return {   
                loader: false,   
                summary: null,          
                orders:[],
                search: {
                    merchant_id: '',
                    start_date: moment().subtract(31,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                form:{
                    merchant_id : '',
                    orderIds: []
                },
                errors:[],
                deleteModal:false,
                order_id:'',
                key:'',
                allSelected: false,
                date: '',
                merchantList: [],
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            this.loadMerchant()
            this.loadData()
            this.loadSlaSummary()
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadMerchant()
                    this.loadData()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            }
        },
        methods:{
            reload () {
                Object.assign(this.search, {
                    merchant_id: '',
                    start_date: moment().subtract(90,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                })
            },
            selectAll () {
                this.form.orderIds = [];
                if (!this.allSelected) {
                    const orders = this.orders
                    for (var order in orders) {
                        this.form.orderIds.push(orders[order].id);
                    }
                } 
            },
            select(orderId) {
                this.form.orderIds.push(orderId)
                this.allSelected = false;
            },
            makeReturn (merchant_id, start_date, end_date) {
                this.$router.push({ name: 'make.return', params: { merchant_id: merchant_id, start_date: start_date, end_date: end_date } })
                // this.$router.push('make-return?merchant_id='+ merchant_id + '&start_date='+ start_date + '&end_date='+ end_date)
            },  
            searchSlaMissedOrder (coverage_area_id) {
                var startDate = ''
                if (coverage_area_id == 1) {
                    startDate = this.summary.sla_isd_date
                } else if (coverage_area_id == 2) {
                    startDate = this.summary.sla_osd_date
                } else if (coverage_area_id == 3) {
                    startDate = this.summary.sla_sa_date
                }
                this.search.start_date = startDate
                this.loadData()
            },       
            searchData () {
                this.loadMerchant()
                this.loadData()
                this.loadSlaSummary()
            },
            async loadMerchant(){     
                this.loader = true 
                const response = await config.getData('/return/returnable-merchants', {})
                this.loader = false
                if (response.status == 200){
                    this.merchantList = response.data
                } else {
                    this.merchantList = []
                } 
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/return/show-cancel', params)
                this.loader = false
                if (response.status == 200){
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getRelationalData(data) {
                const merchantList = this.$store.state.commonObj.merchantList
                const listData = data.map(item => {
                    const tmpData = merchantList.find(merchant => merchant.id === item.merchant_id)
                    const merchantData = { merchant_business: tmpData !== undefined ? tmpData.text : '' }
                    return Object.assign({}, item, merchantData)
                })
                return listData
            },
            async loadSlaSummary(){    
                this.loader = true 
                const params = { merchant_id: this.search.merchant_id }      
                const response = await config.getData('/return/sla-summary', params)
                this.loader = false
                if (response.status == 200){
                    this.summary = response.summary
                } else {
                    this.summary = null
                } 
            },
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>